import React from 'react';
import {InputProps} from './interfaces';
import './styles.scss';

export default function Input(props: InputProps) {
	const {
		id,
		value,
		onBlur,
		onInput,
		labelId,
		labelHeadline,
		errorMessage,
		icon,
		...rest
	} = props;
	const http = 'https://';

	return (
		<label htmlFor={id} id={labelId} onClick={e => e.stopPropagation()}>
			{labelHeadline && <h4>{labelHeadline}</h4>}
			{errorMessage && <p className='error-message p2'>{errorMessage}</p>}
			<div>
				<input
					{...rest}
					id={id}
					value={value}
					autoComplete='off'
					inputMode='none'
					onBlur={e => {
						if (props.type === 'url') {
							e.currentTarget.value = value === http ? '' : (value || http).toString();
						}
						if (onBlur) onBlur(e);
					}}
					onFocus={e => {
						if (props.type === 'url') e.currentTarget.value = (value || http).toString();
						dispatchEvent(
							new CustomEvent('keyboard-focus', {
								detail: {
									input: e.currentTarget,
								},
							})
						);
					}}
					onInput={(e)=>{
						if (props.type === 'url') {
							let valueTemp = (e.target as HTMLInputElement).value;
							if (valueTemp.replace(http, '').includes(http)) {
								// doubled http from pasting complete url
								valueTemp = valueTemp.replace(http, '')
							}
							if (valueTemp !== http && valueTemp.endsWith('/')) {
								valueTemp = valueTemp.slice(0, valueTemp.length - 1)
							}
							if (valueTemp.length < http.length) {
								valueTemp = http;
							}
							(e.target as HTMLInputElement).value = valueTemp;
						}
						if (onInput) onInput(e);
					}}
				/>
				{icon}
			</div>
		</label>
	);
}
