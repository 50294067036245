export function encodeString(string: string | null | undefined) {
	return string
		?.split('')
		.map(c => String.fromCharCode(c.charCodeAt(0) - 13))
		.join('')
}

export function decodeString(string: string | null | undefined) {
	return string
		?.split('')
		.map((c: string) => String.fromCharCode(c.charCodeAt(0) + 13))
		.join('');
}
